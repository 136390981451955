import { Injectable } from '@angular/core';
import {
  Promotion,
  PromotionRegulation,
  UpdateCDSPromotionId,
  UpdatePromotionPrizes,
  UpdatePromotionUrl,
} from '../models/promotion';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User } from '../models/user';
import { AuditorPromotion } from '../models/auditor-promotion';
import { UserService } from './user.service';
import { AccessLevel, FunctionalityName } from '../models/functionality';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService {
  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
  ) {}

  list(queryParamsString?: string) {
    const userHasViewAllPermission = this.userService.userHasPermission(
      FunctionalityName.VIEW_ALL_PROMOS,
      AccessLevel.READ,
    );

    return this.httpClient.get<Promotion[]>(
      `${environment.auditBackendURL}${
        userHasViewAllPermission ? 'listAllPromotions' : 'listUserPromotions'
      }${queryParamsString ?? ''}`,
    );
  }

  getPromotion(id: string) {
    return this.httpClient.get<Promotion>(
      `${environment.auditBackendURL}getPromotionById?id=${id}`,
    );
  }

  listPromotionAuditors(id: string) {
    return this.httpClient.get<User[]>(
      `${environment.auditBackendURL}listPromotionAuditors?promotionId=${id}`,
    );
  }

  assignPromotionToAuditor(data: AuditorPromotion) {
    return this.httpClient.post<AuditorPromotion>(
      `${environment.auditBackendURL}assignPromotionToAuditor`,
      data,
    );
  }

  deletePromotionFromAuditor({ promotionId, auditorId }: AuditorPromotion) {
    const params = new HttpParams()
      .set('promotionId', promotionId)
      .set('auditorId', auditorId);

    return this.httpClient.delete<AuditorPromotion>(
      `${environment.auditBackendURL}deletePromotionFromAuditor`,
      { params },
    );
  }

  updatePromotionUrl({ promotionId, promotionUrl }: UpdatePromotionUrl) {
    return this.httpClient.put<Promotion>(
      `${environment.auditBackendURL}updatePromotionUrl`,
      {
        promotionId,
        promotionUrl: promotionUrl ? encodeURIComponent(promotionUrl) : null,
      },
    );
  }

  updatePromotionPrizes(updatePromotionPrizes: UpdatePromotionPrizes) {
    return this.httpClient.put<Promotion>(
      `${environment.auditBackendURL}updatePromotionPrizes`,
      updatePromotionPrizes,
    );
  }

  updateCDSPromotionId(updateCDSPromotionId: UpdateCDSPromotionId) {
    return this.httpClient.put<Promotion>(
      `${environment.auditBackendURL}updateCDSPromoId`,
      updateCDSPromotionId,
    );
  }

  uploadPromotionRegulation(promotionId: string, fileUrl: string) {
    return this.httpClient.post<PromotionRegulation>(
      `${environment.auditBackendURL}updatePromotionRegulation`,
      {
        promotionId,
        fileUrl: encodeURIComponent(fileUrl),
      },
    );
  }

  downloadLatestPromotionRegulation(promotionId: string) {
    const params = new HttpParams().set('promoId', promotionId);

    return this.httpClient
      .get(`${environment.auditBackendURL}downloadLatestRegulation`, {
        params,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return {
            file: response.body,
            filename:
              response.headers.get('Regulation-Filename') ?? 'regulation',
          };
        }),
      );
  }
}
